import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import map from 'lodash/map';
import CurrencyFormat from 'react-currency-format';
import moment from 'moment';
import _ from 'lodash';
import { graphql } from 'gatsby';

import breakpoints from '../styles/breakpoints';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import { refineHTMLContent } from '../utils/browser';

const Card = styled.div`
  max-width: 700px;
  margin: 0 auto;
  padding: 5rem 1rem;

  @media (max-width: ${breakpoints.md}) {
    padding: 1.5rem 1rem;
  }
`;

const MainContent = styled.div`
  min-height: 100vh;
  background: rgb(245, 247, 250);
`;

const EmptyHeader = styled.header`
  padding: 2.5rem;
  font-size: 1.375rem;
  font-weight: 500;
  text-align: center;
  color: #343434;
  background-color: #ffffff;
  border-bottom: solid 1px #eceff0;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;

  @media (max-width: ${breakpoints.sm}) {
    padding: 1.5rem;
  }
`;

const Header = styled.header`
  padding: 2rem;
  padding-bottom: 1rem;
  font-size: 1.375rem;
  font-weight: 500;
  color: #343434;
  background-color: #ffffff;
  border-bottom: solid 1px #eceff0;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;

  @media (max-width: ${breakpoints.sm}) {
    padding: 1.5rem;
  }

  a {
    color: #343434;
  }
`;

const Body = styled.section`
  padding: 1.5rem 3rem;
  text-align: left;
  background-color: #ffffff;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  overflow: auto;

  @media (max-width: ${breakpoints.sm}) {
    padding: 1.5rem 2rem 2rem;
  }
`;

const Field = styled.span`
  width: 120px;
  height: 30px;
  display: inline-block;
`;

const FieldValue = styled.span`
  font-weight: bold;
`;

const OriginalPrice = styled.span`
  text-decoration: line-through;
  margin-right: 10px;
`;

const NewPrice = styled.span`
  color: red;
  font-weight: bold;
`;

const ViewDetails = styled.div`
  padding: 1.5rem 3rem;
  text-align: center;
  background-color: #ffffff;
`;

const List = styled.div`
  ${Card} + ${Card} {
    padding-top: 0;
  }
`;

function AllPromoPage({ data }) {
  const allPromos = _.filter(data.allTrangPromo.nodes, promo => !!promo.slug);

  return (
    <Layout mainContent={MainContent}>
      <SEO title="Khuyến mãi" />
      {(!allPromos || allPromos.length === 0) && (
        <Card>
          <EmptyHeader>Hiện chưa có khuyến mãi nào. Bạn quay lại sau nhé!</EmptyHeader>
        </Card>
      )}
      <List>
        {allPromos &&
          map(allPromos, promo => (
            <Card key={promo.slug}>
              <Header>{promo.title}</Header>
              <Body>
                <Field>Thời gian:</Field>
                <FieldValue>
                  {`${moment(promo.validFrom).format('DD/MM/YYYY')} - ${moment(
                    promo.validUntil
                  ).format('DD/MM/YYYY')}`}
                </FieldValue>
                <br />
                <Field>Áp dụng:</Field>
                <FieldValue>{promo.subscription.name}</FieldValue>
                <br />
                {!_.isNil(promo.pricing12month) && promo.pricing12month >= 0 && (
                  <>
                    <Field>Gói 12 tháng:</Field>
                    <OriginalPrice>
                      <CurrencyFormat
                        value={promo.subscription.pricing12month}
                        displayType="text"
                        thousandSeparator=","
                      />
                      {` VNĐ`}
                    </OriginalPrice>
                    <NewPrice>
                      <CurrencyFormat
                        value={promo.pricing12month}
                        displayType="text"
                        thousandSeparator=","
                      />
                      {` VNĐ`}
                    </NewPrice>
                  </>
                )}
                {!_.isNil(promo.pricing5month) && promo.pricing5month >= 0 && (
                  <>
                    <Field>Gói 5 tháng:</Field>
                    <OriginalPrice>
                      <CurrencyFormat
                        value={promo.subscription.pricing5month}
                        displayType="text"
                        thousandSeparator=","
                      />
                      {` VNĐ`}
                    </OriginalPrice>
                    <NewPrice>
                      <CurrencyFormat
                        value={promo.pricing5month}
                        displayType="text"
                        thousandSeparator=","
                      />
                      {` VNĐ`}
                    </NewPrice>
                  </>
                )}
                {!_.isNil(promo.pricing3month) && promo.pricing3month >= 0 && (
                  <>
                    <Field>Gói 3 tháng:</Field>
                    <OriginalPrice>
                      <CurrencyFormat
                        value={promo.subscription.pricing3month}
                        displayType="text"
                        thousandSeparator=","
                      />
                      {` VNĐ`}
                    </OriginalPrice>
                    <NewPrice>
                      <CurrencyFormat
                        value={promo.pricing3month}
                        displayType="text"
                        thousandSeparator=","
                      />
                      {` VNĐ`}
                    </NewPrice>
                  </>
                )}
                {promo.appliedFor === 'signup' && (
                  <>
                    <br />
                    <FieldValue>Chỉ áp dụng cho đăng ký mới!</FieldValue>
                  </>
                )}
                <br />
                <br />
                <div
                  dangerouslySetInnerHTML={{
                    __html: refineHTMLContent(promo.content.brief || promo.content.extended),
                  }}
                />
              </Body>
              <ViewDetails>
                <a href={`/promos/${promo.slug}`}>Xem chi tiết</a>
              </ViewDetails>
            </Card>
          ))}
      </List>
    </Layout>
  );
}

AllPromoPage.propTypes = {
  data: PropTypes.shape({
    allTrangPromo: {
      nodes: PropTypes.arrayOf(
        PropTypes.shape({
          content: PropTypes.shape({
            brief: PropTypes.string,
            extended: PropTypes.string,
          }),
          validFrom: PropTypes.string,
          validUntil: PropTypes.string,
          pricing12month: PropTypes.number,
          pricing3month: PropTypes.number,
          pricing5month: PropTypes.number,
        })
      ),
    },
  }).isRequired,
};

export default AllPromoPage;

export const pageQuery = graphql`
  query {
    allTrangPromo {
      nodes {
        slug
        code
        title
        appliedFor
        state
        subscription {
          name
          code
          pricing12month
          pricing3month
          pricing5month
        }
        content {
          brief
          extended
        }
        validFrom
        validUntil
        pricing12month
        pricing3month
        pricing5month
      }
    }
  }
`;
